import {
  IHostProps,
  IWixStatic,
  useEnvironment,
  useWixSdk,
} from '@wix/yoshi-flow-editor';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { selectLayout, selectMessages, selectSettings } from '../features';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../components/AiAssistantWidget/stylesParams';
import settingsParams from '../components/AiAssistantWidget/settingsParams';
import { iconsAsDataTextDict } from '../components/AiAssistantWidget/Settings/components';
import { expandWidget } from '../features/layout/layoutThunks';

type MobileActionBarButtonConfig = {
  visible?: boolean;
  notifications?: boolean;
  color?: string;
  iconSvgContent?: string;
};

type HostSDK_SetMobileActionBarButton = {
  mobile: {
    setMobileActionBarButton: (
      config: MobileActionBarButtonConfig,
      cb?: () => void,
    ) => void;
    setMobileFullScreenMode: (enabled: boolean) => void;
    registerToMobileActionTriggered: (cb: () => void) => void;
  };
};

type WixSDKSetMobileActionBarButton = {
  setMobileActionBarButton: (config: MobileActionBarButtonConfig) => void;
};

type WixSDK =
  | (IWixStatic & {
      Mobile: WixSDKSetMobileActionBarButton;
      removeEventListener: (event: string, handler: () => void) => void;
    })
  | null;

export const useMobileActionBarButton = (_host: IHostProps) => {
  const host = _host as IHostProps & HostSDK_SetMobileActionBarButton;
  const { Wix } = useWixSdk() as { Wix: WixSDK };

  const dispatch = useAppDispatch();
  const { isMobile, isEditor, isPreview } = useEnvironment();
  const hasUnread = useAppSelector(selectMessages.hasUnread);
  const isOpened = useAppSelector(selectLayout.isVisible);
  const shouldShowWidget = useAppSelector(selectSettings.shouldShowWidget);
  const styles = useStyles();
  const settings = useSettings();

  const color = useMemo(
    () => styles.get(stylesParams.minimizedButtonBackground).value,
    [styles],
  );

  const iconSvgContent = useMemo(
    () => iconsAsDataTextDict[settings.get(settingsParams.minimizedButtonIcon)],
    [settings],
  );

  const setOpened = useCallback(() => {
    if (isPreview && isMobile) {
      return;
    }
    dispatch(expandWidget());
  }, [dispatch, isMobile, isPreview]);

  useEffect(() => {
    if (isEditor || !isMobile || !host?.mobile?.setMobileFullScreenMode) {
      return;
    }

    host.mobile.setMobileFullScreenMode(isOpened);
  }, [isOpened, host, isMobile, isEditor]);

  useEffect(() => {
    if (!isMobile || !host?.mobile?.registerToMobileActionTriggered) {
      return;
    }

    host.mobile.registerToMobileActionTriggered(setOpened);
  }, [host, isMobile, setOpened]);

  /**
   * this is only for Editor's mobile version as we have there old WixSDK
   */
  useEffect(() => {
    if (!isEditor || !isMobile || !Wix?.Mobile) {
      return;
    }

    Wix.Mobile.setMobileActionBarButton({
      color,
      iconSvgContent,
      visible: true, // always visible in editor
      notifications: false,
    });
  }, [isMobile, Wix, color, iconSvgContent, isEditor]);

  /**
   * this is only for live site as we have there hostSDK and don't have old WixSDK
   */
  useEffect(() => {
    if (isEditor || !isMobile || !host?.mobile?.setMobileActionBarButton) {
      return;
    }

    host.mobile.setMobileActionBarButton({
      color,
      iconSvgContent,
      visible: !isOpened && shouldShowWidget,
      notifications: hasUnread,
    });
  }, [
    host,
    isMobile,
    hasUnread,
    isOpened,
    color,
    shouldShowWidget,
    iconSvgContent,
    dispatch,
    isEditor,
  ]);
};
