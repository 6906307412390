import {
  ListMessagesRequest,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import { MESSAGES_PAGE_SIZE } from './config';
import { APIMessage, Message } from './messagesSlice';
import { DuplexerMessage } from './messagesThunks';

export const queryBuilder = (cursor?: string): ListMessagesRequest => {
  return {
    cursorPaging: { limit: MESSAGES_PAGE_SIZE, cursor },
  };
};

const ALLOWED_MESSAGE_TYPES = new Set([
  MessageType.QUESTION,
  MessageType.ANSWER,

  MessageType.INTRO,
  MessageType.LEGAL,

  MessageType.CONTACT_FORM,
  MessageType.CONTACT_FORM_SUBMITTED,
]);

export const SPECIAL_MESSAGE_TYPES = new Set([
  MessageType.INTRO,
  MessageType.LEGAL,
  MessageType.CONTACT_FORM,
  MessageType.CONTACT_FORM_SUBMITTED,
]);

export function formatDate(date: Date | string | number) {
  if (typeof date === 'number') {
    return date;
  }
  if (date instanceof Date) {
    return date.getTime();
  }
  try {
    const d = new Date(date);
    return d.getTime();
  } catch (err) {
    console.error(`Failed to format date [${typeof date}] ${date} `);
    return 0;
  }
}

export const formatMessage = (
  m: APIMessage | DuplexerMessage,
  replaceDate?: number,
): Message => {
  return {
    ...m,
    id: m.id!,
    createdDate: replaceDate ?? formatDate(m.createdDate ?? 0),
  };
};

export function formatMessages(
  messages: (APIMessage | DuplexerMessage)[] | undefined,
): Message[] {
  return (messages ?? [])
    .filter((m) => Boolean(m.id))
    .map((m) => formatMessage(m));
}

export const isAllowedMessage = ({
  messageType,
}: APIMessage | DuplexerMessage): boolean =>
  Boolean(messageType) && ALLOWED_MESSAGE_TYPES.has(messageType!);
