import React, { useCallback, useLayoutEffect } from 'react';
import { IconButton, IconButtonThemes } from 'wix-ui-tpa';
import { ChevronLeft, ChevronRight } from '@wix/wix-ui-icons-common/on-stage';
import { st, classes, cssStates } from './ScrollingGallery.st.css';
import { useIntersectionObserver } from '../../../../../hooks';

type ScrollingGalleryProps = {
  children: React.ReactNode;
  arialLabel?: string;
};

export const ScrollingGallery = ({
  children,
  arialLabel,
}: ScrollingGalleryProps) => {
  const [refElement, setRefElement] = React.useState<HTMLDivElement | null>(
    null,
  );

  const ref = React.useRef<HTMLDivElement>(null);

  const { left, right, items } = useIntersectionObserver(refElement, children);
  const onClickArrow = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      switch (e.currentTarget.name) {
        case 'left': {
          left?.scrollIntoView({ inline: 'end', block: 'nearest' });
          break;
        }
        case 'right': {
          right?.scrollIntoView({ inline: 'start', block: 'nearest' });
          break;
        }
      }
    },
    [left, right],
  );

  useLayoutEffect(() => {
    if (ref.current) {
      setRefElement(ref.current);
    }
  }, []);

  return (
    <div className={st(classes.root)} aria-label={arialLabel}>
      <IconButton
        className={st(
          classes.arrow,
          classes.arrowLeft,
          cssStates({ visible: Boolean(left) }),
        )}
        theme={IconButtonThemes.Box}
        icon={<ChevronLeft />}
        name="left"
        onClick={onClickArrow}
      />

      <div className={st(classes.scroller)} ref={ref}>
        <div className={st(classes.flexLayoutSizeFix)}>
          <div className={st(classes.scrollerItems)}>{items}</div>
        </div>
      </div>

      <IconButton
        className={st(
          classes.arrow,
          classes.arrowRight,
          cssStates({ visible: Boolean(right) }),
        )}
        theme={IconButtonThemes.Box}
        icon={<ChevronRight />}
        name="right"
        onClick={onClickArrow}
      />
    </div>
  );
};
