import React, { useEffect } from 'react';

import { st, classes } from './Markdown.st.css';

type MarkdownProps = {
  text: string;
};

const DummyMarkdown = (props: { children: React.ReactNode }) => {
  return <>{props?.children}</>;
};

const components: Partial<{
  [TagName in keyof JSX.IntrinsicElements]:
    | React.ComponentType<JSX.IntrinsicElements[TagName]>
    | keyof JSX.IntrinsicElements;
}> = {
  p: ({ children }) => <p className={st(classes.p)}>{children}</p>,
  h1: ({ children }) => <h1 className={st(classes.h1)}>{children}</h1>,
  h2: ({ children }) => <h2 className={st(classes.h2)}>{children}</h2>,
  h3: ({ children }) => <h3 className={st(classes.h3)}>{children}</h3>,
  h4: ({ children }) => <h4 className={st(classes.h4)}>{children}</h4>,
  h5: ({ children }) => <h5 className={st(classes.h5)}>{children}</h5>,
  h6: ({ children }) => <h6 className={st(classes.h6)}>{children}</h6>,
  pre: ({ children }) => <pre className={st(classes.pre)}>{children}</pre>,
  blockquote: ({ children }) => (
    <blockquote className={st(classes.blockquote)}>{children}</blockquote>
  ),
  ul: ({ children }) => <ul className={st(classes.ul)}>{children}</ul>,
  ol: ({ children }) => <ol className={st(classes.ol)}>{children}</ol>,
  li: ({ children }) => <li className={st(classes.li)}>{children}</li>,
  dl: ({ children }) => <dl className={st(classes.dl)}>{children}</dl>,
  dd: ({ children }) => <dd className={st(classes.dd)}>{children}</dd>,
  dt: ({ children }) => <dt className={st(classes.dt)}>{children}</dt>,
};

export const Markdown = ({ text }: MarkdownProps) => {
  const [ReactMarkdown, setReactMarkdown] =
    React.useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    if (!ReactMarkdown) {
      import('react-markdown').then((module) => {
        setReactMarkdown(() => module.default);
      });
    }
  }, [ReactMarkdown]);

  if (!ReactMarkdown) {
    return <DummyMarkdown>{text}</DummyMarkdown>;
  }

  return (
    <div data-hook="Markdown">
      <ReactMarkdown className={st(classes.root)} components={components}>
        {text}
      </ReactMarkdown>
    </div>
  );
};
