import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  getMessagesHistory,
  selectMessages,
  queryBuilder,
} from '../../../../../features';
import { useCallback, useEffect } from 'react';

export const useHistoryLoader = () => {
  const dispatch = useAppDispatch();
  const shouldLoadMessages = useAppSelector(selectMessages.shouldLoadMessages);
  const isLoadingHistory = useAppSelector(selectMessages.isLoadingHistory);
  const pagingMetadata = useAppSelector(selectMessages.pagingMetadata);

  useEffect(() => {
    if (shouldLoadMessages) {
      dispatch(getMessagesHistory(queryBuilder()));
    }
  }, [shouldLoadMessages, dispatch]);

  return useCallback(async () => {
    if (!isLoadingHistory && pagingMetadata?.cursors?.next) {
      dispatch(getMessagesHistory(queryBuilder(pagingMetadata.cursors.next)));
    }
  }, [dispatch, isLoadingHistory, pagingMetadata]);
};
