import { useMemo } from 'react';

import { Duplexer, TransportError, VirtualSocket } from '@wix/duplexer-js';
import manifest from '../../.application.json';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { config } from '../config';

export type SocketAPI = {
  duplexer: Duplexer;
  connection: VirtualSocket;
  disconnect: () => void;
};

const isDev = process.env.NODE_ENV !== 'production';

export function useDuplexer(instance?: string): SocketAPI | null {
  const { isEditor } = useEnvironment();

  return useMemo(() => {
    if (isEditor || !instance) {
      return null;
    }
    const duplexer = new Duplexer(config.duplexerSocketsServerUrl, {
      instanceUpdater: {
        getInstance: () => instance,
      },
    });

    const connection = duplexer.connect({ appDefId: manifest.appDefinitionId });

    if (isDev) {
      connection.on('@duplexer:connected', () => {
        console.log('@duplexer:connected');
      });
      connection.on('@duplexer:disconnected', (e?: TransportError) => {
        console.log('@duplexer:disconnected', e);
      });
      connection.on('@duplexer:connect_error', (e: TransportError) => {
        console.log('@duplexer:connect_error', e);
      });
    }

    const disconnect = () => {
      connection.removeAllListeners();
    };

    return { duplexer, connection, disconnect };
  }, [instance, isEditor]);
}
