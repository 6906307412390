import React, { useCallback, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from '@wix/wix-ui-icons-common/on-stage';
import {
  Image,
  ImageResizeOptions,
  DotNavigation,
  IconButton,
  IconButtonThemes,
} from 'wix-ui-tpa';
import { st, classes, cssStates } from './ProductGallery.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ImageBlock } from '../ImageBlock';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../stylesParams';

type ProductGalleryProps = {
  images: string[]; // Make it product item to get name for alt
  isSmall?: boolean;
};

// TODO: figure out a better way
const stepLarge = 320 - 24; // Size minus padding
const stepSmall = 250 - 24;
const stepMobile = 215 - 24;

export const ProductGallery: React.FC<
  ProductGalleryProps & React.HTMLAttributes<HTMLElement>
> = ({ images, isSmall, ...rootElementAttrs }) => {
  const { isMobile } = useEnvironment();
  const [currentIndex, setCurrentIndex] = React.useState(
    // Math.round(((images.length - 1) / 2) | 0), // Array should have at least one item
    0, // Array should have at least one item
  );
  const styles = useStyles();
  const step =
    (isMobile ? stepMobile : isSmall ? stepSmall : stepLarge) -
    (styles.get(stylesParams.messagesCardBorderWidth) ?? 0) * 2;
  const isMultiple = useMemo(() => images.length > 1, [images]);

  const marginLeft = useMemo(
    () =>
      (!isMultiple || currentIndex === 0 ? 0 : -(currentIndex * step)) + 'px',
    [currentIndex, step, isMultiple],
  );

  const showLeftArrow = useMemo(
    () => isMultiple && currentIndex > 0,
    [currentIndex, isMultiple],
  );
  const showRightArrow = useMemo(
    () => isMultiple && currentIndex < images.length - 1,
    [currentIndex, images, isMultiple],
  );

  const onClickArrow = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      switch (e.currentTarget.name) {
        case 'left': {
          setCurrentIndex((prev) => prev - 1);
          break;
        }
        case 'right': {
          setCurrentIndex((prev) => prev + 1);
          break;
        }
      }
    },
    [],
  );

  return (
    <div
      {...rootElementAttrs}
      className={`${st(classes.root)} ${rootElementAttrs.className}`}
      style={{ ...rootElementAttrs.style, maxWidth: `${step}px` }}
    >
      <ImageBlock aspectRatio={1}>
        <div className={st(classes.scroller)} style={{ marginLeft }}>
          {images.map((url, i) => (
            <Image
              className={st(classes.image)}
              key={i}
              src={url}
              resize={ImageResizeOptions.cover}
              aspectRatio="square"
            />
          ))}
        </div>
      </ImageBlock>
      {isMultiple && (
        <IconButton
          className={st(
            classes.arrow,
            classes.arrowLeft,
            cssStates({ visible: Boolean(showLeftArrow) }),
          )}
          theme={IconButtonThemes.Box}
          icon={<ChevronLeft />}
          name="left"
          onClick={onClickArrow}
        />
      )}
      {isMultiple && (
        <IconButton
          className={st(
            classes.arrow,
            classes.arrowRight,
            cssStates({ visible: Boolean(showRightArrow) }),
          )}
          theme={IconButtonThemes.Box}
          icon={<ChevronRight />}
          name="right"
          onClick={onClickArrow}
        />
      )}
      {isMultiple && (
        <DotNavigation
          className={st(classes.navigation)}
          length={images.length}
          showBorder
          currentIndex={currentIndex}
          onSelect={setCurrentIndex}
        />
      )}
    </div>
  );
};
