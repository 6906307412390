import {
  FormSubmissionPayload,
  OtherPayload,
  PagePayload,
  PayloadComponentConfig,
  ProductPayload,
  SupportedPayloadType,
} from './types';
import { Product } from '../Product';
import { PagePayload as Page } from '../PagePayload';
import React from 'react';
import { FormSubmittedPayload } from '../FormSubmittedPayload';
import { GenericPayload } from '../GenericPayload';

// order of payload types to show for mixed payloads
export const payloadsOrder: SupportedPayloadType[] = [
  'products',
  'site_pages',
  'form_submitted',
] as const;
// set for O(1) checks if we support the payload type
export const supportedPayloadTypes = new Set(payloadsOrder);

export const payloadTypeToComponentConfig: {
  default: PayloadComponentConfig;
} & Record<SupportedPayloadType, PayloadComponentConfig> = {
  // Generic payload
  default: {
    builder: (payload: OtherPayload, { isSmall }) => (
      <GenericPayload isSmall={Boolean(isSmall)} payload={payload} />
    ),
  },
  products: {
    useGallery: true,
    builder: (payload: ProductPayload, { isSmall, hasSpecialContainer }) => (
      <Product
        isSmall={Boolean(isSmall)}
        images={[payload.img]}
        title={payload.title}
        price={payload.price}
        link={payload.url}
        noImagesGallery={hasSpecialContainer}
      />
    ),
  },
  site_pages: {
    builder: (payload: PagePayload, { isSmall }) => (
      <Page
        isSmall={Boolean(isSmall)}
        image={payload.img}
        title={payload.title}
        link={payload.url}
      />
    ),
  },
  form_submitted: {
    builder: (payload: FormSubmissionPayload, { isSmall }) => (
      <FormSubmittedPayload
        isSmall={Boolean(isSmall)}
        email={payload.email}
        name={payload.name}
        phone={payload.phone}
      />
    ),
  },
};
