import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  addMessage,
  selectFlags,
  selectMessages,
  selectSettings,
} from '../../../../../features';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Sender,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import { useTranslation } from '@wix/yoshi-flow-editor';

type Sequence = [boolean, boolean, boolean];

export const useIntroSequence = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const settingsLoaded = useAppSelector(selectSettings.isLoaded);
  const messagesLoaded = useAppSelector(selectMessages.isLoaded);

  const introMessage = useAppSelector(selectSettings.introMessage);
  const legalMessage = useAppSelector(selectSettings.legalMessage);
  const offlineShouldShowContactForm = useAppSelector(
    selectSettings.offlineShouldShowContactForm,
  );
  const maybeSendIntro = useAppSelector(selectMessages.shouldSendIntro);
  const maybeSendLegal = useAppSelector(selectMessages.shouldSendLegal);
  const isContact = useAppSelector(selectFlags.isContact);
  const noContactFormOnLastPage = useAppSelector(
    selectMessages.noContactFormOnLastPage,
  );

  /**
   * set `true` if resolved or not required.
   *
   * then each sequence item will call itself only if previos is `true` and
   * would not call itself if it's index is `true`.
   */
  const [sequence, setSequence] = useState<Sequence>([false, false, false]);

  const isSequenceFulfilled = useMemo(
    () => sequence.every((s) => s),
    [sequence],
  );

  const shouldFullfillSequence = useMemo(
    () => settingsLoaded && messagesLoaded,
    [settingsLoaded, messagesLoaded],
  );

  const shouldSendIntro = useMemo(
    () => Boolean(maybeSendIntro && introMessage),
    [maybeSendIntro, introMessage],
  );
  const shouldSendLegal = useMemo(
    () => Boolean(maybeSendLegal && legalMessage),
    [maybeSendLegal, legalMessage],
  );
  const shouldSendContact = useMemo(
    () => !isContact && noContactFormOnLastPage && offlineShouldShowContactForm,
    [isContact, noContactFormOnLastPage, offlineShouldShowContactForm],
  );

  const dispatchIntro = useCallback(async () => {
    if (sequence[0]) {
      return;
    }

    await dispatch(
      addMessage({
        text: introMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.INTRO,
      }),
    );

    setSequence((s) => [true, s[1], s[2]]);
  }, [dispatch, introMessage, sequence]);

  const dispatchLegal = useCallback(async () => {
    if (!sequence[0] || sequence[1]) {
      return;
    }

    await dispatch(
      addMessage({
        text: legalMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.LEGAL,
      }),
    );
    setSequence((s) => [true, true, s[2]]);
  }, [dispatch, legalMessage, sequence]);

  const dispatchContactForm = useCallback(async () => {
    if (!sequence[1] || sequence[2]) {
      return;
    }

    await dispatch(
      addMessage({
        text: t('app.widget.form.offlineBeforeText'),
        sender: Sender.ASSISTANT,
        messageType: MessageType.CONTACT_FORM,
      }),
    );

    setSequence((s) => [s[0], true, true]);
  }, [dispatch, t, sequence]);

  useEffect(() => {
    if (!shouldFullfillSequence || isSequenceFulfilled) {
      return;
    }

    if (shouldSendIntro) {
      if (!sequence[0]) {
        dispatchIntro();
      }
    } else {
      setSequence((s) => [true, s[1], s[2]]);
    }

    if (shouldSendLegal) {
      if (!sequence[1]) {
        dispatchLegal();
      }
    } else {
      setSequence((s) => [s[0], true, s[2]]);
    }

    if (shouldSendContact) {
      if (!sequence[2]) {
        dispatchContactForm();
      }
    } else {
      setSequence((s) => [s[0], s[1], true]);
    }
  }, [
    isSequenceFulfilled,
    shouldFullfillSequence,
    sequence,
    dispatchIntro,
    dispatchLegal,
    dispatchContactForm,
    shouldSendIntro,
    shouldSendLegal,
    shouldSendContact,
  ]);

  return {
    isSequenceFulfilled,
  };
};
