import {
  createHttpClientMock,
  IHttpClientMock,
} from '@wix/yoshi-flow-editor/testkit/http-client/client';
import { AnyScenario } from '@wix/yoshi-flow-editor/testkit/http-client';

import React, { useContext } from 'react';

export const HttpClientContext = React.createContext<IHttpClientMock | null>(
  null,
);

export const HttpClientProvider: React.FC<{
  scenarios: AnyScenario[];
}> = ({ scenarios, children }) => (
  <HttpClientContext.Provider value={createHttpClientMock(scenarios)}>
    {children}
  </HttpClientContext.Provider>
);

export function useMockClient() {
  return useContext(HttpClientContext);
}
