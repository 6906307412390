import { OfflineStrategy } from '@wix/ambassador-innovation-widget-v1-message/types';
import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';

export const value = (state: RootState) => state.settings.value;
export const request = (state: RootState) => state.settings.request;

export const isLoaded = createSelector(value, (v) => Boolean(v));
export const isLoading = createSelector(request, (r) => r === 'loading');
export const isError = createSelector(request, (r) => r === 'error');
export const neverLoaded = createSelector(
  value,
  request,
  (v, r) => v === null && r === 'idle',
);
export const isOnline = createSelector(value, (v) =>
  Boolean(v?.assistantOnline),
);

export const shouldShowWidget = createSelector(
  value,
  isOnline,
  (v, o) =>
    o ||
    (Boolean(v?.widgetSettings?.strategy) &&
      v?.widgetSettings?.strategy !== OfflineStrategy.HIDDEN),
);
export const offlineShouldShowContactForm = createSelector(
  value,
  (v) =>
    !v?.assistantOnline &&
    v?.widgetSettings?.strategy === OfflineStrategy.CONTACT,
);

export const introMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.message,
);
export const introMessage = createSelector(
  introMessageSettings,
  isOnline,
  (m, online) => online && m?.enabled && m?.text,
);
export const legalMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.legalDisclaimer,
);
export const legalMessage = createSelector(
  legalMessageSettings,
  isOnline,
  (m, online) => online && m?.enabled && m?.text,
);

export const contactFormFields = createSelector(
  value,
  (v) => v?.widgetSettings?.contactForm?.fields,
);
