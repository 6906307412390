import React, { DependencyList, useContext, useEffect } from 'react';
import { OnHandlerEvent, OnHandlerReset } from '../../types';

export type EventHandler<T> = (arg: T) => void;
export type ResetHandler = () => void;
// This dynamically adjusted as new components listen events.
const handlersPerEvent: Record<string, EventHandler<any>[]> = {};
const handlersOnReset: ResetHandler[] = [];

export type EditorSettingsEvents = {
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
};

export const EditorSettingsEventsContext =
  React.createContext<EditorSettingsEvents>({
    onHandlerEvent: () => {},
    onHandlerReset: () => {},
  });

export const EditorSettingsEventsProvider: React.FC<{
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
}> = ({ onHandlerEvent, onHandlerReset, children }) => (
  <EditorSettingsEventsContext.Provider
    value={{ onHandlerEvent, onHandlerReset }}
  >
    {children}
  </EditorSettingsEventsContext.Provider>
);

export function useOnEditorSettingsEvent<T>(
  key: string,
  handler: EventHandler<T>,
  deps: DependencyList,
) {
  const { onHandlerEvent } = useContext(EditorSettingsEventsContext);
  // Keys are never deleted as there is no option to unsubscribe after handler.on(***) ,
  // but the list will be empty if no one listens effectively making it do nothing
  useEffect(() => {
    if (!handlersPerEvent[key]) {
      handlersPerEvent[key] = [];
      onHandlerEvent(key, (v: any) =>
        handlersPerEvent[key].forEach((h) => h(v)),
      );
    }
    // `key` is handled in return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHandlerEvent]);

  return useEffect(() => {
    handlersPerEvent[key].push(handler);
    return () => {
      const ind = handlersPerEvent[key].indexOf(handler);
      if (ind >= 0) {
        handlersPerEvent[key].splice(ind, 1);
      }
    };
  }, [handler, key, deps]);
}

export function useOnEditorSettingsReset(
  handler: ResetHandler,
  deps: DependencyList,
) {
  const { onHandlerReset } = useContext(EditorSettingsEventsContext);

  useEffect(() => {
    onHandlerReset(() => handlersOnReset.forEach((h) => h()));
  }, [onHandlerReset]);

  return useEffect(() => {
    handlersOnReset.push(handler);
    return () => {
      const ind = handlersOnReset.indexOf(handler);
      if (ind >= 0) {
        handlersOnReset.splice(ind, 1);
      }
    };
  }, [handler, deps]);
}
