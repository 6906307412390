import React, { useMemo } from 'react';
import {
  Button,
  ButtonPriority,
  ButtonSize,
  Card,
  Image,
  ImageResizeOptions,
  Text,
} from 'wix-ui-tpa';
import { OtherPayload, PayloadCmpProps } from '../ChatMessagePayload/types';
// TODO: make own css file
import { classes, st } from '../Product/Product.st.css';
import { useSiteNavigation } from '../../contexts';
import { ChatCard } from '../ChatCard';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ImageBlock } from '../ImageBlock';

type GenericPayloadProps = PayloadCmpProps & {
  payload: OtherPayload;
};

export const GenericPayload: React.FC<
  GenericPayloadProps & React.HTMLAttributes<HTMLElement>
> = ({ isSmall, payload, ...rootElementAttrs }) => {
  const [t] = useTranslation();
  const hasInfoBlock = useMemo(
    () =>
      !!(
        payload.title ||
        payload.subtitle ||
        payload.description ||
        payload.price
      ),
    [payload],
  );
  const { navigateToUrl, baseUrl } = useSiteNavigation();
  const isOwnLink = payload.url && payload.url.startsWith(baseUrl);

  if (!payload.img && !payload.url && !hasInfoBlock) {
    return null;
  }

  // Button
  if (payload.url && !payload.img) {
    return (
      <div className={`${st(classes.root)} ${rootElementAttrs.className}`}>
        {isOwnLink ? (
          <Button
            onClick={() => navigateToUrl(payload.url!)}
            upgrade
            priority={ButtonPriority.secondary}
            className={st(classes.secondaryButton)}
            size={ButtonSize.tiny}
          >
            {payload.title ?? payload.url}
          </Button>
        ) : (
          <a
            href={payload.url!}
            target="_blank"
            className={st(classes.secondaryButton)}
            rel="noreferrer"
          >
            {payload.title ?? payload.url}
          </a>
        )}
      </div>
    );
  }

  // Card
  return (
    <ChatCard
      {...rootElementAttrs}
      className={`${st(classes.root)} ${rootElementAttrs.className}`}
      isSmall={!!isSmall}
    >
      {payload.img && (
        <Card.Container className={st(classes.section)}>
          <ImageBlock aspectRatio={1}>
            <Image
              className={st(classes.image)}
              src={payload.img}
              resize={ImageResizeOptions.cover}
              aspectRatio="square"
            />
          </ImageBlock>
        </Card.Container>
      )}
      {hasInfoBlock && (
        <Card.Container className={st(classes.section)}>
          {payload.title && (
            <Text className={st(classes.title)}>{payload.title}</Text>
          )}
          {payload.subtitle && (
            <Text className={st(classes.description)}>{payload.subtitle}</Text>
          )}
          {payload.description && (
            <Text className={st(classes.description)}>
              {payload.description}
            </Text>
          )}
          {payload.price && (
            <Text className={st(classes.description)}>{payload.price}</Text>
          )}
        </Card.Container>
      )}
      {payload.url && (
        <Card.Container className={st(classes.section)}>
          <Button
            onClick={() => navigateToUrl(payload.url!)}
            upgrade
            priority={ButtonPriority.primary}
            className={st(classes.button)}
            fullWidth
          >
            {t('app.widget.genericPayload.link')}
          </Button>
        </Card.Container>
      )}
    </ChatCard>
  );
};
