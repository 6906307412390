// All exports here are treated as thunks for communication with worker - use messagesUtility.ts for other exports
import {
  createMessage,
  listMessages,
  getConversation,
} from '@wix/ambassador-innovation-widget-v1-message/http';
import { submitContact } from '@wix/ambassador-contacts-v4-submit-contact/http';
import {
  ListMessagesRequest,
  Sender,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import { v4 as uuidv4 } from 'uuid';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { APIMessage, Message, messagesSlice } from './messagesSlice';
import { getDummyMessages } from './dummyMessages';
import { selectLayout } from '../layout';
import {
  formatMessage,
  formatMessages,
  isAllowedMessage,
  queryBuilder,
  SPECIAL_MESSAGE_TYPES,
} from './utils';
import { FormData } from '../../components/AiAssistantWidget/Widget/components';
import {
  EmailTag,
  PhoneTag,
  SubmitContactRequest,
} from '@wix/ambassador-contacts-v4-submit-contact/types';
import { flagsSlice } from '../flags';
import { FormSubmissionPayload } from '../../components/AiAssistantWidget/Widget/components/ChatMessagePayload/types';
import { selectMessages } from '.';
import { senderIsVisitor } from '../../utils/sender';

export type DuplexerMessage = Partial<APIMessage> & { expiryMs: number };
export type DuplexerMergeConversatoinsMessage = {
  oldConversationIds: string[];
  targetConversationId: string;
};

export const getEditorMockMessages = createAppAsyncThunk(
  'messages/getMock',
  async (_, { dispatch, extra: { t } }) => {
    dispatch(
      messagesSlice.actions.addMessages({
        messages: formatMessages(getDummyMessages(t)),
        pagingMetadata: { tooManyToCount: true, cursors: {} },
      }),
    );
  },
);

export const getMessagesHistory = createAppAsyncThunk(
  'messages/get',
  async (
    query: ListMessagesRequest | undefined = queryBuilder(),
    { dispatch, extra: { httpClient } },
  ) => {
    const resp = await httpClient.request(listMessages(query));
    const { messages, pagingMetadata } = resp.data;

    dispatch(
      messagesSlice.actions.addMessages({
        pagingMetadata,
        messages: formatMessages(messages?.filter(isAllowedMessage)),
      }),
    );
  },
);

export const getConversationId = createAppAsyncThunk(
  'messages/getConversationId',
  async (_, { dispatch, extra: { httpClient } }) => {
    const {
      data: { conversationId: id, contact },
    } = await httpClient.request(getConversation({}));

    if (!id) {
      throw new Error('No conversationId in response!');
    }

    dispatch(messagesSlice.actions.setConversationId(id));
    dispatch(flagsSlice.actions.setIsContact(Boolean(contact)));

    return id;
  },
);

export const subscribeForNewMessages = createAppAsyncThunk(
  'messages/subscribe',
  async (_, { dispatch, getState, extra: { socket, httpClient } }) => {
    if (!socket) {
      // No need to listen in Editor
      return;
    }

    /**
     * TODO: set subscription/connection status in store,
     * handle disconnect/errors with relative status, resubscribe
     */

    const conversation =
      selectMessages.conversationId(getState()) ??
      (await dispatch(getConversationId()).unwrap());

    const channel = socket.connection.subscribe(conversation);
    channel.on('new-message-event', (m: DuplexerMessage) => {
      if (m.id) {
        if (!isAllowedMessage(m)) {
          return;
        }

        // It is possible to get event with a message id before we got API response in which case 2 messages are created
        // this mechanic should solve such cases
        const pendingMessages = selectMessages.pendingMessages(getState());
        if (
          pendingMessages &&
          (senderIsVisitor(m.sender) ||
            (m.messageType && SPECIAL_MESSAGE_TYPES.has(m.messageType)))
        ) {
          return;
        }

        dispatch(messagesSlice.actions.setIsTyping(false));
        const message = formatMessage(m);
        dispatch(messagesSlice.actions.addOrUpdateMessage(message));

        const state = getState();
        if (selectLayout.isMinimized(state)) {
          dispatch(messagesSlice.actions.setUnread(true));
        }
      } else if (m.expiryMs) {
        // Typing notification
        dispatch(messagesSlice.actions.setIsTyping(true));
        // TODO: add timed removal of typing indicator
      }
    });
    channel.on(
      'new-conversation-event',
      async (m: DuplexerMergeConversatoinsMessage) => {
        if (m.targetConversationId) {
          channel.removeAllListeners();
          await dispatch(getConversationId());
          dispatch(
            messagesSlice.actions.resetConversation(m.targetConversationId),
          );
        }
      },
    );
  },
);

export type AddMessagePayload = Partial<Message> & {
  text: string;
};

export const addMessage = createAppAsyncThunk(
  'messages/add',
  async (
    msg: AddMessagePayload,
    { dispatch, extra: { httpClient }, getState },
  ) => {
    const conversationId = selectMessages.conversationId(getState());
    const optimisticUpdateMessage: Message = {
      id: uuidv4(),
      sender: Sender.UOU,
      messageType: MessageType.QUESTION,
      createdDate: Date.now(),
      conversationId,
      ...msg,
    };

    dispatch(
      messagesSlice.actions.addOptimisticMessage(optimisticUpdateMessage),
    );

    const { text, sender, messageType, answerTo, structPayloads } =
      optimisticUpdateMessage;
    const messageReq = createMessage({
      message: { text, sender, messageType, answerTo, structPayloads },
    });

    const resp = await httpClient.request(messageReq);

    if (!resp.data.message?.id) {
      // TODO: make re-send button flow
      throw new Error('No message in response, or it has no id!');
    }
    const { message } = resp.data;
    dispatch(
      messagesSlice.actions.updateMessage({
        id: optimisticUpdateMessage.id,
        changes: formatMessage(
          message,
          message.messageType && SPECIAL_MESSAGE_TYPES.has(message.messageType)
            ? optimisticUpdateMessage.createdDate
            : undefined,
        ),
      }),
    );
  },
);

export const setRead = createAppAsyncThunk(
  'messages/read',
  async (_, { dispatch }) => {
    dispatch(messagesSlice.actions.setUnread(false));
  },
);

export const submitVisitorAsContact = createAppAsyncThunk(
  'messages/submitContact',
  async (
    { name, email, phone, message, formId }: FormData,
    { dispatch, extra: { httpClient } },
  ) => {
    const structure: SubmitContactRequest = {
      info: {
        emails: { items: [{ email, tag: EmailTag.UNTAGGED }] },
      },
    };

    if (name) {
      structure.info!.name = { first: name };
    }
    if (phone) {
      structure.info!.phones = { items: [{ phone, tag: PhoneTag.UNTAGGED }] };
    }

    await httpClient.request(submitContact(structure));

    dispatch(
      addMessage({
        text: ' ',
        sender: Sender.SYSTEM,
        messageType: MessageType.CONTACT_FORM_SUBMITTED,
        answerTo: formId,
        structPayloads: [
          {
            type: 'form_submitted',
            id: uuidv4(),
            name,
            email,
            phone,
          } as FormSubmissionPayload,
        ],
      }),
    );

    if (message) {
      dispatch(addMessage({ text: message, sender: Sender.UOU }));
    }
  },
);
