import { useCallback, useEffect, useMemo, useState } from 'react';
import stylesParams from '../components/AiAssistantWidget/stylesParams';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import {
  IWixSDKContext,
  useEnvironment,
  useWixSdk,
} from '@wix/yoshi-flow-editor';
import { useAppSelector } from '../store';
import settingsParams from '../components/AiAssistantWidget/settingsParams';
import { SettingsMinimizedButtonLayout } from '../components/AiAssistantWidget/Settings/components';

import { selectLayout } from '../features';

type ResizeWindowDeprecatedWixSDK = {
  resizeWindow: (width: number, height: number, callback?: () => void) => void;
};
type WixSDK = (IWixSDKContext['Wix'] & ResizeWindowDeprecatedWixSDK) | null;

const MINIMIZED_WIDTH_FALLBACK = 300;
const MINIMIZED_HEIGHT_FALLBACK = 100;
const MINIMIZED_WIDTH_EXTRA_SPACE = 50;
const MINIMIZED_HEIGHT_EXTRA_SPACE = 20;

const SHADOW_EXTRA_SPACE = 60;

export const useResizeWidgetWindow = () => {
  const styles = useStyles();
  const settings = useSettings();
  const { Wix } = useWixSdk() as { Wix: WixSDK };
  const { isMobile, isEditor } = useEnvironment();

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [refWidth, setRefWidth] = useState<number>(0);
  const [refHeight, setRefHeight] = useState<number>(0);

  const isOpened = useAppSelector(selectLayout.visual) === 'visible';

  const positionHeightFix = useMemo(() => {
    const position = settings.get(
      settingsParams.minimizedButtonPosition,
    ) as SettingsMinimizedButtonLayout;
    switch (position) {
      case 'icon':
        return 15;
      case 'floating':
        return 20;
      case 'sticky':
        return 0;
      default:
        return 0;
    }
  }, [settings]);

  const widgetWidth = useMemo(
    () => styles.get(stylesParams.widgetWidth) + SHADOW_EXTRA_SPACE,
    [styles],
  );

  const getWidgetHeight = useCallback(
    (fullHeight: number) =>
      Math.round((fullHeight * styles.get(stylesParams.widgetHeight)) / 100),
    [styles],
  );

  const minimizedWidth = useMemo(() => {
    if (!refWidth) {
      return MINIMIZED_WIDTH_FALLBACK;
    }

    return refWidth + MINIMIZED_WIDTH_EXTRA_SPACE;
  }, [refWidth]);

  const minimizedHeight = useMemo(() => {
    if (!refHeight) {
      return MINIMIZED_HEIGHT_FALLBACK;
    }

    return refHeight + MINIMIZED_HEIGHT_EXTRA_SPACE + positionHeightFix;
  }, [refHeight, positionHeightFix]);

  useEffect(() => {
    if (!isEditor || isMobile || !Wix) {
      return;
    }

    Wix?.getBoundingRectAndOffsets(({ offsets, rect }) => {
      Wix?.resizeWindow(
        isOpened ? widgetWidth : minimizedWidth,
        isOpened ? getWidgetHeight(offsets.y + rect.height) : minimizedHeight,
      );
    });
  }, [
    isOpened,
    isEditor,
    widgetWidth,
    minimizedWidth,
    minimizedHeight,
    getWidgetHeight,
    Wix,
    isMobile,
  ]);

  useEffect(() => {
    if (!ref) {
      setRefWidth(0);
      setRefHeight(0);
      return;
    }

    const callback = () => {
      setRefWidth(ref.offsetWidth);
      setRefHeight(ref.offsetHeight);
    };

    const o = new ResizeObserver(callback);
    o.observe(ref);

    return () => {
      o.disconnect();
    };
  }, [ref]);

  return { setRef, isOpened };
};
