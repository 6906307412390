import React from 'react';
import { IHostProps } from '@wix/yoshi-flow-editor';
import { useMobileActionBarButton } from '../../../../../hooks';

export interface MobileActionBarButtonProps {
  host: IHostProps;
}

// Extracting `host` from `ChatWrapper` makes the former cacheable for react
export const MobileActionBarButton: React.FC<MobileActionBarButtonProps> = ({
  host,
}) => {
  useMobileActionBarButton(host);

  return null;
};
