import React, { useMemo } from 'react';
import { Message, selectFlags } from '../../../../../features';
import { Card, Badge, BadgePriority } from 'wix-ui-tpa';
import { st, classes, cssStates } from './ChatMessage.st.css';
import { MessageType } from '@wix/ambassador-innovation-widget-v1-message/types';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { AIAvatarIcon, AvatarIcon } from '../svg';
import { ChatMessageWrapper } from '../ChatMessageWrapper';
import { ChatMessagePayload } from '../ChatMessagePayload';
import { ContactForm } from '../ContactForm';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ContactFormDataHooks } from '../ContactForm/ContactFormDataHooks';
import { useAppSelector } from '../../../../../store';
import { Markdown } from '../Markdown';
import {
  senderIsAnswer,
  senderIsAssistant,
  senderIsOwner,
  senderIsVisitor,
} from '../../../../../utils/sender';

export interface ChatMessageProps {
  message?: Message;
  last?: boolean;
}

export const ChatMessage = ({ message, last = false }: ChatMessageProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const isContact = useAppSelector(selectFlags.isContact);

  const isStuffMessage = !senderIsVisitor(message?.sender);

  const aria = useMemo(() => {
    if (!message) {
      return {};
    }
    const { sender } = message;

    if (senderIsAssistant(sender)) {
      return {
        'aria-labelledby': t('app.widget.ariallabel.answer', {
          name: settings.get(settingsParams.textHeader),
        }),
      };
    }

    if (senderIsOwner(sender)) {
      return {
        'aria-label': t('app.widget.ariallabel.intervention'),
      };
    }

    if (senderIsVisitor(sender)) {
      return {
        'aria-label': t('app.widget.ariallabel.question'),
      };
    }
    return {};
  }, [t, message, settings]);

  return (
    <ChatMessageWrapper
      className={last ? st(classes.lastMessage) : ''}
      {...aria}
    >
      <Card
        className={st(
          classes.root,
          cssStates({
            aiAnswer: isStuffMessage,
            userQuestion: !isStuffMessage,
            applyAiAnswerShadow:
              isStuffMessage &&
              settings.get(settingsParams.applyAssistantMessageShadow),
          }),
        )}
      >
        <Card.Container className={st(classes.container)}>
          <div className={st(classes.icon)}>
            {isStuffMessage ? <AIAvatarIcon /> : <AvatarIcon />}
          </div>
          <div className={st(classes.content)}>
            {senderIsOwner(message?.sender) && (
              <Badge priority={BadgePriority.secondary}>
                {t('app.widget.badge.stuffRespond')}
              </Badge>
            )}
            {message && ( // TODO: make better matching message types
              <>
                {message.messageType === MessageType.CONTACT_FORM_SUBMITTED && (
                  <p data-hook={ContactFormDataHooks.SUBMITTED_MESSAGE}>
                    {t('app.widget.form.submitResponse')}
                  </p>
                )}

                {!!message.text?.trim().length && (
                  <Markdown text={message.text} />
                )}
                <ChatMessagePayload message={message} />

                {message.suffixText && <Markdown text={message.suffixText} />}

                {message.messageType === MessageType.CONTACT_FORM &&
                  !isContact && <ContactForm formId={message.id} />}
              </>
            )}
            {isStuffMessage && <div className={st(classes.cardBottomSpacer)} />}
          </div>
        </Card.Container>
      </Card>
    </ChatMessageWrapper>
  );
};
