import React, { useState, useMemo, useLayoutEffect } from 'react';

const findHidden = (state: [Element, boolean][], elem?: HTMLElement) =>
  state.find(([e, isVisible]) => e === elem && !isVisible)?.[0];

export const useIntersectionObserver = (
  root: Element | null,
  children: React.ReactNode,
) => {
  const [state, setState] = useState<[Element, boolean][]>([]);
  const childRefs = React.useRef<HTMLElement[]>([]);

  const items = useMemo(
    () =>
      React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement<any>(child, {
            ref: (el: HTMLElement) => {
              childRefs.current[index] = el;
            },
          });
        }
        return child;
      }),
    [children],
  );

  const [left, right] = useMemo(
    () => [
      findHidden(state, childRefs.current.at(0)),
      findHidden(state, childRefs.current.at(-1)),
    ],
    [state],
  );

  useLayoutEffect(() => {
    if (!root) {
      return;
    }

    const states = new Map<Element, boolean>();

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach(({ target, intersectionRatio }) => {
        states.set(target, intersectionRatio === 1);
      });

      setState(Array.from(states.entries()));
    };

    const observer = new IntersectionObserver(callback, {
      root,
      threshold: [1, 0.02, 0.02, 1],
    });

    childRefs.current.forEach((item) => {
      observer.observe(item);
    });
  }, [items, root]);

  return { left, right, items };
};
