import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { messagesSlice } from '../messages';
import { layoutSlice } from './layoutSlice';

export const expandWidget = createAppAsyncThunk(
  'layout/expand',
  async (_, { dispatch }) => {
    dispatch(layoutSlice.actions.setVisual('visible'));
    dispatch(messagesSlice.actions.setUnread(false));
  },
);

export const minimizeWidget = createAppAsyncThunk(
  'layout/minimize',
  async (_, { dispatch }) => {
    dispatch(layoutSlice.actions.setVisual('minimized'));
  },
);
