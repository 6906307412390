import React from 'react';
import { st, classes, cssStates } from './ChatCard.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export type ChatCardProps = React.HTMLAttributes<HTMLElement> & {
  isSmall?: boolean;
  cardClassName?: string;
};

export const ChatCard: React.FC<ChatCardProps> = ({
  isSmall,
  cardClassName = '',
  children,
  ...attrs
}) => {
  const { isMobile } = useEnvironment();
  return (
    <div
      {...attrs}
      className={`${st(
        classes.root,
        cssStates({ small: isSmall, isMobile }),
      )} ${attrs.className}`}
    >
      <div className={`${st(classes.card)} ${cardClassName}`}>{children}</div>
    </div>
  );
};
