import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { SocketAPI } from '../hooks/useDuplexer';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
  extra: {
    httpClient: import('@wix/yoshi-flow-editor').IHttpClient;
    socket: SocketAPI | null;
    t: import('@wix/yoshi-flow-editor').TFunction;
    bi: import('@wix/yoshi-flow-editor/external-types/bi').OwnerLogger;
  };
}>();
