import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  selectSettings,
  selectFlags,
  getEditorMockMessages,
  subscribeForNewMessages,
  VisualLayoutState,
  selectMessages,
  getConversationId,
} from '../../../../../features';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useResizeWidgetWindow } from '../../../../../hooks';
import { getSettings } from '../../../../../features/settings/settingsThunks';
import { Chat } from '../Chat';
import { MinimizedChat } from '../MinimizedChat';
import {
  expandWidget,
  minimizeWidget,
} from '../../../../../features/layout/layoutThunks';
import {
  useOnEditorSettingsEvent,
  useOnEditorSettingsReset,
} from '../../contexts';
import { useForcedExpand } from '../../../../../hooks/useForcedExpand';

export interface ChatDataWrapperProps {}

export const ChatWrapper: React.FC<ChatDataWrapperProps> = () => {
  const dispatch = useAppDispatch();
  const { isEditor, isMobile } = useEnvironment();
  const shouldGetConversationId = useAppSelector(
    selectMessages.shouldGetConversationId,
  );
  const conversationId = useAppSelector(selectMessages.conversationId);
  const neverLoadedSettings = useAppSelector(selectSettings.neverLoaded);
  const shouldShowWidget = useAppSelector(selectSettings.shouldShowWidget);
  const isSubscribedToDuplexer = useAppSelector(
    selectFlags.isSubscribedToDuplexer,
  );

  const { setRef, isOpened } = useResizeWidgetWindow();

  useOnEditorSettingsEvent(
    'visual',
    (state: VisualLayoutState) => {
      if (!isEditor) {
        return;
      }
      dispatch(state === 'visible' ? expandWidget() : minimizeWidget());
    },
    [],
  );

  useOnEditorSettingsReset(() => dispatch(minimizeWidget()), []);

  useForcedExpand();

  useEffect(() => {
    if (isEditor) {
      dispatch(getEditorMockMessages());
    }
  }, [dispatch, isEditor]);

  useEffect(() => {
    // Settings are needed in Editor as well for displaying minimized widget defferences
    // https://github.com/wix-private/wix-ai-assistant-bot/issues/758
    if (neverLoadedSettings) {
      dispatch(getSettings());
    }

    if (isEditor) {
      return;
    }

    if (shouldGetConversationId) {
      dispatch(getConversationId());
      return; // no need to subscribe for new messages if we don't have conversationId
    }

    if (!isSubscribedToDuplexer && conversationId) {
      dispatch(subscribeForNewMessages());
    }
  }, [
    isEditor,
    neverLoadedSettings,
    isSubscribedToDuplexer,
    shouldGetConversationId,
    conversationId,
    dispatch,
  ]);

  // TODO: this if should be covered by tests in Editor and Live site env
  if (!isEditor && !shouldShowWidget) {
    return null;
  }

  return (
    <>
      {/* Read note at EditorWrapper file */}
      {/* <EditorWrapper> } */}
      {isOpened && <Chat />}
      {!isOpened && !isMobile && <MinimizedChat setRef={setRef} />}
      {/* </EditorWrapper> */}
    </>
  );
};
