import React from 'react';
import { OnHandlerEvent, OnHandlerReset } from '../../../types';
import {
  EditorSettingsEventsProvider,
  NavigateTo,
  NavigateToUrl,
  SiteNavigationProvider,
} from '../../contexts';
import { useGetStore } from '../../../../../store';
import { Provider as ReduxProvider } from 'react-redux';
import { ChatWrapper } from '../ChatWrapper';
import { MobileActionBarButton } from '../MobileActionBarButton';
import { WidgetProps } from '@wix/yoshi-flow-editor';

export type ControllerProps = {
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
  navigation: {
    baseUrl: string;
    navigateTo: NavigateTo;
    navigateToUrl: NavigateToUrl;
  };
  instanceToken: string;
};

export const ControllerValidation: React.FC<WidgetProps<ControllerProps>> = ({
  onHandlerEvent,
  onHandlerReset,
  navigation,
  instanceToken,
  host,
}) => {
  const store = useGetStore(instanceToken!);
  return (
    <div>
      <ReduxProvider store={store}>
        <EditorSettingsEventsProvider
          onHandlerEvent={onHandlerEvent}
          onHandlerReset={onHandlerReset}
        >
          <SiteNavigationProvider navigation={navigation}>
            <ChatWrapper />
            <MobileActionBarButton host={host} />
          </SiteNavigationProvider>
        </EditorSettingsEventsProvider>
      </ReduxProvider>
    </div>
  );
};
