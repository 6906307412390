import React from 'react';
import { ThreeDotsLoader } from 'wix-ui-tpa';
import { st, classes } from './Loader.st.css';
import { ChatMessageWrapper } from '../ChatMessageWrapper';

export const Loader: React.FC = () => (
  <ChatMessageWrapper className={st(classes.root)}>
    <ThreeDotsLoader className={st(classes.loader)} />
  </ChatMessageWrapper>
);
